<template>
    <div class="row grow w-100">
        <div class="col-1">
            <NavSidebar></NavSidebar>
        </div>

        <div class="main col-11 h-100 py-5 padding-subpages">
            <HeaderCard :icons="['projectCreation', 'trash-fill', 'download']" info='false' info_color='red'>
                <template #title>
                    <h4>Projektübersicht <i class="title">{{ getProjectName }}</i></h4>
                </template>
                <template #description>
                    <div style="display: flex; gap: 10px">
                        <div v-if="getProjectMeta.wantedResult !== ''" style="display: flex">
                            <h4> {{ getProjectMeta.wantedResult }} </h4>
                            <span style="margin-left: 10px">|</span>
                        </div>
                        <div v-if="getProjectMeta.field !== ''" style="display: flex">
                            <h4>{{ getProjectMeta.field }}</h4>
                            <span style="margin-left: 10px">|</span>
                        </div>
                        <div v-if="getProjectMeta.targetGroup !== ''">
                            <h4>{{ getProjectMeta.targetGroup }} </h4>
                        </div>
                    </div>
                    <div v-if="getProjectDescription !== ''">
                        {{ getProjectDescription }}
                    </div>
                </template>
            </HeaderCard>

            <section id="tool-sections">
                <h5>Toolbereiche</h5>
                <div class="tool-sections">
                    <div class="option">
                        <div :class="!showValueReflection ? 'empfohlen' : ''"></div>
                        <OverviewCollapse id=1 label="ELSI-Wertereflexion" :text="wertesystem" :link="true"
                            class="clickable" linkTo="/valueReflection" labelButton="Werte einordnen" icon="TargetIcon" />
                        <div v-if="showValueReflection">
                            <b-card class="overflow-auto" body-class="text-center"
                                style="width: 100%; height: 100%; margin-top: 2rem; border: none"
                                footer-class="align-self-center" footer-tag="footer" footer-border-variant="none"
                                footer-bg-variant="white">
                                <ValueReflection ref="valueReflection" :isOverview=true />
                            </b-card>
                        </div>
                    </div>
                    <div class="option">
                        <div :class="!getShowProfileInOverview && showValueReflection ? 'empfohlen' : ''"></div>
                        <OverviewCollapse id=2 label="ELSI-Profil" :text="profil" :link="true" class="clickable"
                            linkTo="/profile" labelButton="Profil erstellen" icon="AlignLeftIcon" />
                        <div v-if="getShowProfileInOverview">
                            <b-card class="overflow-auto" body-class="text-center"
                                style="width: 100%; height: 100%; margin-top: 2rem; border: none"
                                footer-class="align-self-center" footer-tag="footer" footer-border-variant="none"
                                footer-bg-variant="white">

                                <Profile :isOverview=true />
                            </b-card>
                        </div>
                    </div>
                    <div class="option">
                        <div :class="showValueReflection && getShowProfileInOverview && !showCardsInOverview ? 'empfohlen' : ''"></div>
                        <OverviewCollapse id=3 label="ELSI-Karten" :text="karten" :link="true" class="clickable"
                            linkTo="/elsi_cards" labelButton="Karten ansehen" icon="GridIcon" />
                        <div>
                            <b-card class="overflow-auto" body-class="text-center"
                                style="width: 100%; height: 100%; margin-top: 2rem; background: none; border: none"
                                footer-class="align-self-center" footer-tag="footer" footer-border-variant="none"
                                footer-bg-variant="white">
                                <ElsiCards :isOverview=true />
                            </b-card>
                        </div>
                    </div>
                    <div class="option">
                        <div :class="!showProjectReflection && showValueReflection && getShowProfileInOverview && showCardsInOverview ? 'empfohlen' : ''"></div>
                        <OverviewCollapse id=4 label="Projektreflexion" :text="reflexion" :link="true" class="clickable"
                            linkTo="/reflexion" labelButton="Projekt reflektieren" icon="CheckSquareIcon" />
                        <div v-if="showProjectReflection">
                            <b-card class="overflow-auto" body-class="text-center"
                                style="width: 100%; height: 100%; margin-top: 2rem;background: none; border: none"
                                footer-class="align-self-center" footer-tag="footer" footer-border-variant="none"
                                footer-bg-variant="white">
                                <ProjectReflection :isOverview=true />
                            </b-card>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <b-modal size="lg" v-model="showRememberDownloadCompleted" title="Projekt herunterladen" :hide-footer=true>
            <Download :showIntro=true />
        </b-modal>

        <b-modal size="lg" v-model="showRememberDownloadStart"
            title="Ihre Daten können Sie sichern, indem Sie Ihr Projekt herunterladen" :hide-footer=true>
            <div style="display: flex; align-items: start;">
                <feather-icon icon="DownloadIcon" />
                <span style="margin-left: 20px">Vergessen Sie nicht, am Ende jeder Sitzung Ihr Projekt als JSON-Datei
                    herunterzuladen. Diese können Sie wieder ins Tool importieren, um zu
                    einem späteren Zeitpunkt weiter an Ihrem Projekt zu arbeiten.</span>
            </div>
        </b-modal>

    </div>
</template>

<script>

import NavSidebar from "@/components/Nav_Sidebar.vue"
import HeaderCard from "@/components/Header_Card.vue"
import OverviewCollapse from "@/components/Overview_Collapse.vue"
import ProgressBar from "@/components/ProgressBar.vue"
import ValueReflection from "../valueReflection/ValueReflection"
import Profile from "../profile/Profile.vue"
import ElsiCards from "../elsi_cards/ElsiCards.vue"
import ProjectReflection from "../reflection/ProjectReflection.vue"
import NavHeader from "../../../components/Nav_Header.vue"
import Download from '../../../components/Modal_Project_Download.vue';
import FeatherIcon from '../../../components/FeatherIcon.vue';

export default {
    name: 'project-overview',
    components: {
        NavSidebar,
        HeaderCard,
        ProgressBar,
        OverviewCollapse,
        ValueReflection,
        Profile,
        ElsiCards,
        ProjectReflection,
        NavHeader,
        Download,
        FeatherIcon
    },
    computed: {
        getProjectName() {
            return this.$store.getters['survey/getProjectMeta'].nameProject
        },
        getProjectDescription() {
            return this.$store.getters['survey/getProjectMeta'].projectDescription
        },
        getProjectMeta() {
            return this.$store.getters['survey/getProjectMeta']
        },
        getShowProfileInOverview() {
            return this.$store.getters['survey/getShowProfileInOverview']
        },
        showValueReflection() {
            return this.$store.getters['survey/getShowValueReflection']
        },
        showCardsInOverview() {
            return this.$store.getters['survey/showCardsInOverview']
        },
        showProjectReflection() {
            return this.$store.getters['survey/getShowProjectReflection']
        },
        oneProjectReflectionQuestionAnswered() {
            return this.$store.getters['survey/oneProjectReflectionQuestionAnswered']
        },
        getProjectCreated() {
            return this.$store.getters['survey/getProjectCreated']
        },
        getProjectCompleted() {
            return this.$store.getters['survey/getProjectCompleted']
        },
    },
    mounted() {
        // Show download reminder, ONE TIME once all tool areas have been completed.  
        if (!this.$store.getters['survey/getProjectCompleted']) {
            if (this.showValueReflection && this.getShowProfileInOverview && this.showCardsInOverview && this.oneProjectReflectionQuestionAnswered) {
                this.$store.commit('survey/SET_PROJECT_COMPLETED')
                this.showRememberDownloadCompleted = true
            }
        }
        // Show download reminder ONE TIME at start of a new project
        if (!this.$store.getters['survey/getProjectCreated']) {
            this.$store.commit('survey/SET_PROJECT_CREATED')
            this.showRememberDownloadStart = true
        }
    },
    created() {
        this.$store.commit('survey/UPDATE_CATEGORY_MAX_ANSWERS_MAX_POINTS')
    },
    data() {
        return {
            wertesystem: "Finden Sie die zentralen Werte für Ihre Forschung heraus. Nehmen Sie dabei unterschiedliche Perspektiven ein, um ethische Konflikte aufdecken und lösen zu können.",
            profil: "Ermitteln Sie anhand von Fragen, welche ELSI-Kategorien für Ihr Projekt besonders wichtig und wie wahrscheinlich bestimmte Auswirkungen sind.",
            karten: "Informieren Sie sich zu relevanten ELSI-Kategorien und lassen Sie sich von Fallbeispielen inspirieren.",
            reflexion: "Reflektieren Sie Ihr eigenes Projekt anhand einer Checkliste und leisten Sie den Transfer von den ELSI-Inhalten zum eigenen Forschungsvorhaben.",
            showRememberDownloadCompleted: false,
            showRememberDownloadStart: false,
        }
    }
}

</script>

<style lang="scss" scoped>
.option {
    position: relative;
}

.empfohlen {
    position: absolute;
    inset: 0;
    height: 8px;
    border-top: 8px solid $elsi-color-lightblue;
    border-radius: 6px 6px 0 0;

    &::before {
        content: 'empfohlen';
        color: $elsi-color-lightblue;
        position: absolute;
        top: -28px;
        right: 0;
        font-weight: 600;
        font-size: 12px;
        line-height: 140%;
    }
}

.title {
    color: $elsi-color-lightblue;
}

section#tool-sections {
    display: flex;
    flex-direction: column;
    // calc: 1rem margin from collapsible
    //padding: 8px 40px calc(32px - 1rem) 40px;
    padding-top: 2rem;

    h5 {
        font-style: bold;
        font-size: 16px;
        line-height: 16px;
        /* identical to box height, or 114% */
        color: $elsi-color-schrift-normal;
        padding-bottom: 5px;
    }

    .tool-sections {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 1.5rem;
    }
}
</style>
